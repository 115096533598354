.brewerySpinner {
  margin: auto;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.brewery-info-panel {
  padding: 0px;
}
.slide-pane__content {
  position: relative;
  overflow-y: auto;
  padding: 10px 10px;
  flex: 1 1 auto;
}
