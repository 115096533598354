body {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 0px;
  margin-left: 0px;
}

.beerdb-map {
 width:100%;
 height:100%;
 position: absolute;
}

.search-panel {
  width: 300px !important;
  margin-left: auto;
}

.brewery-info-panel {
  position:fixed;
  bottom: 0px;
  height: 325px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
